import React, { useEffect, useState } from "react";
import { capitalize, get, isNull, isUndefined } from "lodash";
import styled, { css } from "styled-components";
import { NavLink } from "react-router-dom";
import { downArrowWhite, alpsLogo, StyledEditIcon, addWhite, removeIcon, searchIcon } from "../../app/icons";
import { HeaderNavListInterface, NavLinkType, NavListInterface } from "../ducks/types";
import { ApplicationStateInterface, ContextReducerInterface, MessageType, ProjectListInterface } from "../../app/duck/types";
import { getLinkWithParams } from "../ducks/utils";
import PRIMARY_NAV_LIST from "../ducks/primary-nav-list";
import PrimaryNavigation from "./primary-navigation";
import { StyledGradientButtonText, StyledGradientButton } from "../../beta/home/styledHome";
import RequestDemo from "../../auto-login/components/Request-demo-popup";
import { ALPS_LOCAL_STORAGE_KEY, COOKIE_LFT_USER, COOKIE_SUB_PLAN, COPY } from "../../app/const";
import { getCookie, isTrialUser } from "../../app/duck/utils";
import { useDispatch, useSelector } from "react-redux";
import {
    PAGE_OPTIMIZER_URL,
    PROJECT_SETUP_ADD_KEYWORDS_URL,
    PROJECT_SETUP_DOMAIN_DETAILS_URL,
    PROJECT_SETUP_MANAGE_KEYWORDS_URL
} from "../ducks/routes-url-constant";
import Alert from "../../app/styledComponents/alert";
import { BUTTON_TYPE, StyledPrimaryButton, StyledInvertButton } from "../../app/styledComponents/button";
import { setProjectCreateJourney, setProjectEditJourney } from "../../project-setup/ducks/actions";
import { useHistory } from "react-router";
import { getProjectDetails } from "../../../src/beta/home/ducks/actions";
import UpgradeNow from "./Upgrade-now/UpgradeNowComponent/upgrade-now";
import SuccessPopUp from "./Upgrade-now/UpgradeNowComponent/success-popup";
import ModalComponent from "../../app/styledComponents/modal";
import {
    StyledSuccessPopUpWrapper,
    StyledUpgradeNowContainer,
    StyledUpgradeNowModal,
    StyledTrialExpirationLink,
    StyledTrialExpirationMessage,
    StyledTrialExpirationMsgContainer
} from "./Upgrade-now/StyledSimulator";
import Toast from "../../app/styledComponents/toastCommon";
import { getContext } from "../../app/duck/context-container";
import InputComponent, { StyledInput } from "../../app/styledComponents/input";
import DomainLimitPopup from "./domainlimtpopup";

export const StyledPrimaryNavigation = styled.header`
    background: #00acce;
    height: 50px;
    padding: 0 48px;
    box-sizing: border-box;
    z-index: 110;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    display: flex;
    justify-content: space-between;
`;

export const StyledHeaderLogo = styled.div`
    background: url(${alpsLogo}) no-repeat;
    cursor: pointer;
    display: inline-block;
    width: 103px;
    height: 40px;
    margin-top: 14px;
    background-size: 103px;
`;

export const StyledNavContainer = styled.nav`
    float: right;
    margin-top: 14px;
    display: flex;
    flex-shrink: 0;
`;

export const StyledNavLeftContainer = styled.div`
    display: flex;
`;

export const StyledNavUl = styled.ul`
    display: flex;
`;

export const StyledSecondarySubUl = styled.nav`
    width: 177px;
    padding: ${(props: { isAddProject?: boolean }) => (props.isAddProject ? "0 7px 10px 20px" : "0 20px 10px 20px;")};
    border-radius: 3px;
    text-transform: ${(props: { isAddProject?: boolean }) => (props.isAddProject ? "capitalize" : "uppercase")};
    background: #fff;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.34);
    position: absolute;
    top: 100%;
    box-sizing: border-box;
    right: 0px;
    display: none;
    z-index: 1;
    word-break: break-word;
    max-height: 292px;
    overflow-x: hidden;
    overflow-y: scroll;
`;

export const StyledNavList = styled.li`
    float: left;
    width: 24px;
    position: relative;
    margin-right: 25px;
    padding-bottom: 9px;
    /* cursor: pointer; */
    cursor: ${isTrialUser() ? "default" : "pointer"};
    opacity: ${isTrialUser() ? "0.5" : "1"};
    pointer-events: ${isTrialUser() ? "none" : "auto"};
    &:nth-child(2) {
        background-position: left 0;
    }
    &:nth-last-child(2) {
        margin-top: -4px;
    }
    &:last-child {
        background-position: right 0;
        margin-right: 0px;
        padding-right: 0px;
        width: 24px;
        height: 22px;
    }
    ${(props: StyledNavListPropInterface) =>
        props.icon &&
        css`
            background: url(${props.icon}) no-repeat;
            /* background-position: 0px; */
        `}
    ${(props: StyledNavListPropInterface) =>
        props.hasLabel &&
        css`
            display: flex;
            align-items: center;
            width: auto;
            background-position: 8px 3px;
            ${StyledNavListNavLink} {
                padding-top: 0px;
            }
        `}
`;

export const StyledNavListDropdownTitle = styled.span`
    display: block;
    padding-left: 34px;
    font-size: 12px;
    /* padding-top: 5px; */
    color: #fff;
    height: 22px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    font-weight: bold;
    word-break: break-word;
    white-space: pre-wrap;
`;

export const StyledTitleBar = styled.div`
    max-width: ${(props: { dropdownTitleList?: boolean }) => (props.dropdownTitleList ? "none" : "100px")};
    .addProject {
        display: flex;
        color: #1fb1d0;
    }
`;

export const StyledNavListDropdown = styled(StyledNavList)`
    position: relative;
    padding-right: 20px;
    padding-bottom: 14px;
    width: auto;
    max-width: 160px;
    &.active {
        ${StyledSecondarySubUl} {
            display: flex;
            flex-direction: column;
        }
    }
    &:not(:last-child) {
        &:after {
            background: url(${downArrowWhite}) no-repeat;
            content: "";
            width: 16px;
            height: 16px;
            display: block;
            position: absolute;
            right: 0;
            top: 4px;
        }
    }
    &:last-child ${StyledNavListDropdownTitle} {
        display: none;
    }
`;

export const StyledAddProjectTabHeader = styled(StyledNavListDropdown)`
    &:not(:last-child) {
        &:after {
            background: url(${(props: { haveProjects: boolean }) => (props.haveProjects ? downArrowWhite : "")}) no-repeat;
            content: "";
            width: 16px;
            height: 16px;
            display: block;
            position: absolute;
            right: 0;
            top: 4px;
        }
    }
`;

export const StyledNavListNavLink = styled(NavLink)`
    display: block;
    padding-left: 34px;
    font-size: 12px;
    padding-top: 5px;
    color: #fff;
    height: 22px;
`;

export const StyledNavIconLabel = styled.span`
    font-family: Lato;
    font-size: 12px;
    font-weight: bold;
    color: #fff;
`;

export const StyledNavListAnchor = styled.a`
    display: block;
    padding-left: 34px;
    font-size: 12px;
    padding-top: 5px;
    color: #fff;
    height: 22px;
    &.trial-user {
        pointer-events: none;
    }
`;

export const StyledSecondarySubNavLink = styled(NavLink)`
    float: left;
    margin-top: 10px;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: normal;
    color: #343434;
    cursor: pointer;
    line-height: 15px;
`;

export const StyledProjectNavLink = styled(StyledSecondarySubNavLink)`
    display: flex;
    justify-content: space-between;
`;

export const StyledEditProjectIcon = styled.div`
    background: url(${StyledEditIcon}) no-repeat;
    content: "";
    width: 16px;
    height: 16px;
    display: block;
`;

export const StyledDeleteProjectIcon = styled.div`
    background: url(${removeIcon}) no-repeat;
    content: "";
    width: 16px;
    height: 16px;
    display: block;
`;

export const StyledIconContainer = styled.div`
    display: flex;
    justify-content: end;
    margin-top: 8px;
`;

export const StyledSecondarySubAnchor = styled.a`
    float: left;
    margin-top: 10px;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: normal;
    color: #343434;
    cursor: ${isTrialUser() ? "default" : "pointer"};
    line-height: 15px;
    color: ${isTrialUser() ? "#afafaf" : "#343434"};
`;

export const StyledFooterDeleteButton = styled(StyledPrimaryButton)`
    margin: 10px 10px;
`;

export const StyledFooterCancelButton = styled(StyledInvertButton)`
    height: 30px;
    margin: 10px 10px;
`;
export const StyledInputBoxContainer = styled.div`
    display: flex;
    ${StyledInput} {
        background: url(${searchIcon}) no-repeat;
        background-position: right 10px center;
        padding-right: 30px;
        margin: 10px 0px 0px -3px;
    }
`;

interface HeaderPropsInterface {
    activeRouteUrl: string;
    links: HeaderNavListInterface[];
    projectList: HeaderNavListInterface;
    context: ContextReducerInterface;
    activeDropdownId?: string;
    isHeaderVisible?: boolean;
    toggleDropdown(link: HeaderNavListInterface, event: React.MouseEvent): void;
    handleSimulationNavigation(): void;
    handleRedirection(to: string, isExternal?: boolean, id?: string, title?: string): void;
    handleDeleteProject(projectId: number): void;
}

interface HeaderIconNavPropsInterface {
    link: HeaderNavListInterface;
    context: ContextReducerInterface;
    activeDropdownId?: string;
    label?: string;
    PageNotFoundVisible?: boolean;
    toggleDropdown(link: HeaderNavListInterface, event: React.MouseEvent): void;
    handleRedirection(to: string, isExternal?: boolean): void;
}

interface HeaderIconNavStateInterface {
    isDropdownOpen: boolean;
}

interface StyledNavListPropInterface {
    icon: string;
    hasLabel?: boolean;
}

interface HeaderChildListInterface {
    subLinks: NavListInterface[];
    context: ContextReducerInterface;
    isAddProject?: boolean;
    addSearch?: boolean;
    handleRedirection(to: string, isExternal?: boolean, id?: string, title?: string): void;
    toggleDeleteConfirmationModal?(id: number, title: string): void;
}

const cookie = getCookie(COOKIE_SUB_PLAN) ? getCookie(COOKIE_SUB_PLAN) : getCookie(COOKIE_LFT_USER);

const HeaderChildList: React.FunctionComponent<HeaderChildListInterface> = ({
    subLinks,
    context,
    isAddProject,
    handleRedirection,
    toggleDeleteConfirmationModal,
    addSearch = false
}: HeaderChildListInterface) => {
    const dispatch = useDispatch();
    const [searchValue, setSearchValue] = React.useState("");
    const filteredOptions = subLinks
        .filter(
            (subLink: NavListInterface) =>
                subLink.title.toLowerCase().includes(searchValue.toLowerCase()) && subLink.title.toLowerCase() !== "add-project"
        )
        .sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()));
    const history = useHistory();
    const { tenantCode } = getContext();
    const handleSearchData = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.stopPropagation();
        setSearchValue(e.target.value);
    };
    const preMetaAddProject = localStorage.getItem(ALPS_LOCAL_STORAGE_KEY);
    const lsAddProject = !isNull(preMetaAddProject) ? JSON.parse(preMetaAddProject) : {};
    const toPathAddProject = subLinks[0].to.replace(":tenantCode", tenantCode as string);

    const [isPopupVisible, setPopupVisible] = useState(false);
    const [domainLimit] = useSelector((state: ApplicationStateInterface) => [
        state.context.domainLimit,
    ]);
    const handleDoaminLimitClick = (e: React.MouseEvent<HTMLElement>) => {
        if (domainLimit === 0) {
            e.preventDefault();
            setPopupVisible(true);
        } else {
            e.preventDefault();
            if (subLinks[0].title === "Add Project") {
                lsAddProject.currentProjectIDUnderEdit = undefined;
                lsAddProject.currentProjectNameUnderEdit = undefined;
                localStorage.setItem(ALPS_LOCAL_STORAGE_KEY, JSON.stringify(lsAddProject));
                dispatch(setProjectCreateJourney());
            }
            handleRedirection(toPathAddProject, false, subLinks[0].id, subLinks[0].title);
        }

    };

    const closePopup = () => {
        setPopupVisible(false);
    };

    return (
        <>
            {isPopupVisible && (<DomainLimitPopup closePopup={closePopup} />)}
            <StyledSecondarySubUl isAddProject={isAddProject}>
                {addSearch && (
                    <>
                        <StyledProjectNavLink
                            onClick={handleDoaminLimitClick}
                            key={0}
                            to={getLinkWithParams({ context, to: subLinks[0].to, isExternal: false })}
                            id={subLinks[0].id}
                        >
                            <StyledTitleBar>
                                <div className="addProject">
                                    <div>+</div>
                                    <div>{subLinks[0].title}</div>
                                </div>
                            </StyledTitleBar>
                        </StyledProjectNavLink>

                        <div style={{ width: "100%", borderBottom: "1px solid #e2e2e2", margin: "5px -6px" }} />
                        <StyledInputBoxContainer
                            onClick={(e: React.MouseEvent<HTMLElement>) => {
                                e.nativeEvent.stopImmediatePropagation();
                                e.stopPropagation();
                            }}
                        >
                            <InputComponent
                                instanceId={"projectSearchBox"}
                                placeholder={"Search"}
                                value={searchValue}
                                onChangeHandler={handleSearchData}
                            />
                        </StyledInputBoxContainer>
                    </>
                )}
                {filteredOptions &&
                    filteredOptions.map((subLink: NavListInterface, index) => {
                        const { title, type, to, id, isProjectUrl } = subLink;
                        const toPath = to.replace(":tenantCode", tenantCode as string);
                        const dropdownTitleList = ["Change Account", "Change Password", "Logout"].includes(title as string);
                        if (isProjectUrl && !context.projectId) {
                            return null;
                        }
                        if (cookie) {
                            if (title === "Change Account") {
                                return null;
                            }
                        }
                        if (type === NavLinkType.INTERNAL) {
                            const preMeta = localStorage.getItem(ALPS_LOCAL_STORAGE_KEY);
                            const ls = !isNull(preMeta) ? JSON.parse(preMeta) : {};
                            return (
                                <div id={`${id}-dropdown-list`}>
                                    <StyledProjectNavLink
                                        onClick={(e: React.MouseEvent<HTMLElement>) => {
                                            e.preventDefault();
                                            if (subLink.title === "Change Account" || subLink.title === "Change Password") {
                                                localStorage.setItem(ALPS_LOCAL_STORAGE_KEY, JSON.stringify(ls));
                                            } else {
                                                ls.currentProjectIDUnderEdit = subLink.id;
                                                ls.currentProjectNameUnderEdit = subLink.title;
                                                localStorage.setItem(ALPS_LOCAL_STORAGE_KEY, JSON.stringify(ls));
                                            }
                                            handleRedirection(toPath, false, id, title);
                                        }}
                                        key={index}
                                        to={getLinkWithParams({ to, context, isExternal: false })}
                                        id={id}
                                    >
                                        {" "}
                                        {subLink.title !== "Add Project" && (
                                            <StyledTitleBar dropdownTitleList={dropdownTitleList}>{title}</StyledTitleBar>
                                        )}
                                    </StyledProjectNavLink>
                                    <StyledIconContainer>
                                        {subLink.showEditIcon && (
                                            <StyledEditProjectIcon
                                                onClick={() => {
                                                    ls.currentProjectIDUnderEdit = subLink.id;
                                                    ls.currentProjectNameUnderEdit = subLink.title;
                                                    localStorage.setItem(ALPS_LOCAL_STORAGE_KEY, JSON.stringify(ls));
                                                    dispatch(setProjectEditJourney({ id: subLink.id }));
                                                    if (subLink.id) {
                                                        dispatch(
                                                            getProjectDetails({
                                                                projectId: subLink.id
                                                            })
                                                        );
                                                    }
                                                    history.push("/setup/domain-details");
                                                }}
                                            />
                                        )}
                                        {subLink.showEditIcon && (
                                            <StyledDeleteProjectIcon
                                                onClick={() =>
                                                    toggleDeleteConfirmationModal && toggleDeleteConfirmationModal(Number(id), title)
                                                }
                                            />
                                        )}
                                    </StyledIconContainer>
                                </div>
                            );
                        }
                        return (
                            <StyledSecondarySubAnchor
                                key={id}
                                onClick={(e: React.MouseEvent<HTMLElement>) => {
                                    e.preventDefault();
                                    handleRedirection(getLinkWithParams({ to, context, isExternal: true }), true);
                                }}
                                href={getLinkWithParams({ to, context, isExternal: true })}
                                id={id}
                            >
                                {title}
                            </StyledSecondarySubAnchor>
                        );
                    })}
            </StyledSecondarySubUl>
        </>
    );
};

const getProjectName = (name: string): string => {
    const PROJECT_NAME_LENGTH = 45;
    if (name.length > PROJECT_NAME_LENGTH) {
        return name.slice(0, PROJECT_NAME_LENGTH).concat("...");
    }
    return name;
};

class HeaderDropdownNav extends React.Component<HeaderIconNavPropsInterface, HeaderIconNavStateInterface> {
    constructor(props: HeaderIconNavPropsInterface) {
        super(props);
        this.state = {
            isDropdownOpen: false
        };
    }

    public render(): JSX.Element {
        const { link, context, toggleDropdown, activeDropdownId, handleRedirection, PageNotFoundVisible } = this.props;
        const { child, icon } = link;
        return (
            <>
                {!PageNotFoundVisible && (
                    <StyledNavListDropdown
                        id={link.id}
                        icon={icon}
                        onClick={(event: React.MouseEvent) => {
                            toggleDropdown(link, event);
                        }}
                        className={`${activeDropdownId && activeDropdownId === link.id ? "active" : ""}`}
                    >
                        <StyledNavListDropdownTitle id={`${link.id}_title`} title={capitalize(get(context, "projectName", ""))}>
                            {getProjectName(get(context, "projectName", ""))}
                        </StyledNavListDropdownTitle>
                        {child && <HeaderChildList handleRedirection={handleRedirection} subLinks={child} context={context} />}
                    </StyledNavListDropdown>
                )}
            </>
        );
    }
}

// tslint:disable-next-line: no-any
const AddProjectTab = (props: any) => {
    const { projectList, context, toggleDropdown, activeDropdownId, handleRedirection, toggleDeleteConfirmationModal } = props;
    const { child, icon } = projectList;
    const preMeta = localStorage.getItem(ALPS_LOCAL_STORAGE_KEY);
    const ls = !isNull(preMeta) ? JSON.parse(preMeta) : {};
    const [isPopupVisible, setPopupVisible] = useState(false);
    const [domainLimit] = useSelector((state: ApplicationStateInterface) => [
        state.context.domainLimit,
    ]);

    const closePopup = () => {
        setPopupVisible(false);
    };
    return (
        <>
            {isPopupVisible && (<DomainLimitPopup closePopup={closePopup} />)}
            <StyledAddProjectTabHeader
                id={projectList.id}
                icon={icon}
                haveProjects={projectList.child.length}
                onClick={(event: React.MouseEvent) => {
                    if ((ls.currentProjectNameUnderEdit || ls.projectName || getProjectName(get(context, "projectName", ""))) === "" && domainLimit === 0) {
                        setPopupVisible(true);
                    } else {
                        !projectList.child.length ? handleRedirection(PROJECT_SETUP_DOMAIN_DETAILS_URL, false) : toggleDropdown(projectList, event);
                    }
                }}
                className={`${activeDropdownId && activeDropdownId === projectList.id ? "active" : ""}`}
            >
                <StyledNavListDropdownTitle id={`${projectList.id}_title`} title={capitalize(get(context, "projectName", ""))}>
                    {ls.currentProjectNameUnderEdit || ls.projectName || getProjectName(get(context, "projectName", "")) || COPY.ADD_PROJECT}
                </StyledNavListDropdownTitle>
                {child.length ? (
                    <HeaderChildList
                        toggleDeleteConfirmationModal={toggleDeleteConfirmationModal}
                        handleRedirection={handleRedirection}
                        subLinks={child}
                        context={context}
                        isAddProject={true}
                        addSearch={true}
                    />
                ) : null}
            </StyledAddProjectTabHeader>
        </>
    );
};

const HeaderIconNav: React.FunctionComponent<HeaderIconNavPropsInterface> = ({
    link,
    context,
    label,
    handleRedirection
}: HeaderIconNavPropsInterface) => {
    const dispatch = useDispatch();
    const preMeta = localStorage.getItem(ALPS_LOCAL_STORAGE_KEY);
    const ls = !isNull(preMeta) ? JSON.parse(preMeta) : {};
    return (
        <StyledNavList icon={link.icon} className={isTrialUser() ? "trial-user" : ""} hasLabel={!isUndefined(label)}>
            {link.type === NavLinkType.INTERNAL ? (
                <StyledNavListNavLink
                    onClick={(e: React.MouseEvent<HTMLElement>) => {
                        e.preventDefault();
                        dispatch(setProjectEditJourney({ id: ls.currentProjectIDUnderEdit }));
                        handleRedirection(getLinkWithParams({ context, to: link.to, isExternal: false }), false);
                    }}
                    to={getLinkWithParams({ context, to: link.to, isExternal: false })}
                    id={link.id}
                />
            ) : (
                <StyledNavListAnchor
                    onClick={(e: React.MouseEvent<HTMLElement>) => {
                        if (link.type === NavLinkType.EXTERNAL) {
                            e.preventDefault();
                            handleRedirection(link.to, undefined);
                        }
                    }}
                    href={link.to}
                    target={link.type === NavLinkType.EXTERNAL ? "_blank" : ""}
                    id={link.id}
                    className={isTrialUser() ? "trial-user" : ""}
                />
            )}
            {!isUndefined(label) && (
                <StyledNavIconLabel
                    onClick={(e: React.MouseEvent<HTMLElement>) => {
                        e.preventDefault();
                        dispatch(setProjectEditJourney({ id: ls.currentProjectIDUnderEdit }));
                        handleRedirection(getLinkWithParams({ context, to: link.to, isExternal: false }), false);
                    }}
                >
                    {label}
                </StyledNavIconLabel>
            )}
        </StyledNavList>
    );
};

const Header: React.FunctionComponent<HeaderPropsInterface> = ({
    links,
    context,
    projectList,
    toggleDropdown,
    activeDropdownId,
    activeRouteUrl,
    isHeaderVisible,
    handleSimulationNavigation,
    handleRedirection,
    handleDeleteProject
}: HeaderPropsInterface): JSX.Element => {
    const dispatch = useDispatch();
    const [active, setActive] = useState<boolean>(false);
    const [projectId, setProjectId] = useState<number>(0);
    const [projectTitle, setProjectTitle] = useState<string>("");
    const [isShowDeleteProjectConfirmationModal, setShowDeleteProjectConfirmationModalsd] = useState<boolean>(false);
    const [openUpgradeNow, setOpenUpgradeNow] = useState<boolean>(false);
    const [projectKeywordCount, setProjectKwCount] = useState<number>(0);
    const [openSuccessPopUp, setOpenSuccessPopUp] = useState<boolean>(false);
    const [endDate, setEndDate] = useState<string>("");
    const [daysRemaining, setDaysRemaining] = useState<number | null>(null);

    const { projectId: selectedProjectId } = useSelector((state: ApplicationStateInterface) => state?.context);
    const [PageNotFoundVisible] = useSelector((state: ApplicationStateInterface) => [state.userAccount.PageNotFoundVisible]);
    const addkeywords = { id: "add-keywords", to: PROJECT_SETUP_ADD_KEYWORDS_URL, title: "", icon: addWhite, type: NavLinkType.INTERNAL };
    const manageKeywords = {
        id: "manage-keywords",
        to: PROJECT_SETUP_MANAGE_KEYWORDS_URL,
        title: "",
        icon: addWhite,
        type: NavLinkType.INTERNAL
    };

    useEffect(() => {
        if (!isUndefined(selectedProjectId) && projectList.child?.length) {
            const kwCount = projectList?.child?.find((item: NavListInterface) => Number(item.id) === selectedProjectId)?.keywordCount;
            setProjectKwCount(kwCount ? kwCount : 0);
        }
        const preMeta = localStorage.getItem(ALPS_LOCAL_STORAGE_KEY);
        const ls = !isNull(preMeta) ? JSON.parse(preMeta) : {};
        if (!ls.default_project && context.projectsList && !context.projectId) {
            const projID = context.projectsList?.sort((a: ProjectListInterface, b: ProjectListInterface) =>
                !isNull(a.name) && !isNull(b.name) && a.name < b.name ? -1 : 1
            );
            const projectID = (projID?.[0]?.id as unknown) as number;
            ls.currentProjectIDUnderEdit = projectID;
            ls.currentProjectNameUnderEdit = projID?.[0]?.name;
            localStorage.setItem(ALPS_LOCAL_STORAGE_KEY, JSON.stringify(ls));
            if (projectId) {
                dispatch(
                    getProjectDetails({
                        projectId: projectID || ls?.currentProjectIDUnderEdit || context.projectId,
                        toBeClearUserSelection: true
                    })
                );
            }
        }
    }, [projectList.child?.length]);

    const toggleDeleteConfirmationModal = (projID?: number, title?: string) => {
        setShowDeleteProjectConfirmationModalsd(!isShowDeleteProjectConfirmationModal);
        setProjectId(projID || 0);
        setProjectTitle(title || "");
    };

    const onCloseUpgradeNowModal = (args: boolean) => {
        isOpenByDefault.current = false;
        setOpenUpgradeNow(args);
    };
    const isOpenByDefault = React.useRef(true);

    useEffect(() => {
        const storedDate = localStorage.getItem("_sed");
        if (storedDate) {
            const storedDateObj = new Date(storedDate);
            const currentDate = new Date();
            const timeDifference = storedDateObj.getTime() - currentDate.getTime();
            const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));

            setDaysRemaining(daysDifference);
        }
        const sed = storedDate?.slice(0, 10);
        setEndDate(isUndefined(sed) ? "" : sed);
    }, []);

    const pathname = window.location.pathname;
    return (
        <StyledPrimaryNavigation>
            <StyledNavLeftContainer>
                <StyledHeaderLogo id="logo" onClick={handleSimulationNavigation} />
                {cookie || !isHeaderVisible || PageNotFoundVisible ? null : (
                    <PrimaryNavigation
                        activeRouteUrl={activeRouteUrl}
                        links={PRIMARY_NAV_LIST}
                        context={context}
                        toggleDropdown={toggleDropdown}
                        activeDropdownId={activeDropdownId}
                        handleRedirection={handleRedirection}
                        popup={setActive}
                    />
                )}
            </StyledNavLeftContainer>
            {cookie && cookie !== "STA" && !isNull(daysRemaining) && (
                <Toast
                    id={4}
                    onRemove={() => null}
                    dissolveTime={4000}
                    title={``}
                    type={MessageType.ERROR}
                    message={`Your free trial ends in ${daysRemaining} days`}
                    autoClose={false}
                    linkText={`Upgrade here`}
                    showCloseIcon={false}
                    onClickLinkText={() => {
                        setOpenUpgradeNow(true);
                    }}
                    styles={{
                        LinkTextWrapper: StyledTrialExpirationLink,
                        ToastMessage: StyledTrialExpirationMessage,
                        ToastContainer: StyledTrialExpirationMsgContainer
                    }}
                />
            )}

            <StyledNavContainer>
                {cookie && cookie !== "STA" && ((isOpenByDefault.current && pathname === PAGE_OPTIMIZER_URL) || openUpgradeNow) && (
                    <ModalComponent
                        id="upgrade-now"
                        title={
                            <UpgradeNow
                                instanceId="upgrade-now"
                                expireUser={false}
                                endDate={endDate}
                                current_plan={cookie === "LFT" ? "LFT" : "TRI"}
                                upgradeModalOpen={onCloseUpgradeNowModal}
                                successModalOpen={setOpenSuccessPopUp}
                            />
                        }
                        styles={{ Modal: StyledUpgradeNowModal, Head: StyledUpgradeNowContainer }}
                        onCloseHandler={() => onCloseUpgradeNowModal(false)}
                    />
                )}
                {openSuccessPopUp && (
                    <ModalComponent
                        id="success-popup"
                        title={<SuccessPopUp instanceId="success-popup" />}
                        styles={{ Modal: StyledUpgradeNowModal, Head: StyledSuccessPopUpWrapper }}
                        onCloseHandler={() => null}
                    />
                )}
                {isTrialUser() && (
                    <StyledGradientButton href={process.env.REQUEST_DEMO_URL} target="_blank">
                        <StyledGradientButtonText>{COPY.REQUEST_DEMO_TEXT}</StyledGradientButtonText>
                    </StyledGradientButton>
                )}
                <StyledNavUl>
                    {projectList.child?.length && selectedProjectId ? (
                        cookie || !isHeaderVisible || PageNotFoundVisible ? null : (
                            <HeaderIconNav
                                label={projectKeywordCount > 0 ? "Manage Keywords" : "Add Keywords"}
                                key={projectKeywordCount > 0 ? manageKeywords.id : addkeywords.id}
                                link={projectKeywordCount > 0 ? manageKeywords : addkeywords}
                                context={context}
                                toggleDropdown={toggleDropdown}
                                activeDropdownId={activeDropdownId}
                                handleRedirection={handleRedirection}
                            />
                        )
                    ) : null}
                    {cookie || !isHeaderVisible || PageNotFoundVisible ? null : (
                        <AddProjectTab
                            key={projectList.id}
                            projectList={projectList}
                            context={context}
                            toggleDropdown={toggleDropdown}
                            activeDropdownId={activeDropdownId}
                            handleRedirection={handleRedirection}
                            toggleDeleteConfirmationModal={toggleDeleteConfirmationModal}
                        />
                    )}
                    {links.map((link: HeaderNavListInterface) =>
                        link.child && link.child.length ? (
                            <HeaderDropdownNav
                                key={link.id}
                                link={link}
                                context={context}
                                toggleDropdown={toggleDropdown}
                                activeDropdownId={activeDropdownId}
                                handleRedirection={handleRedirection}
                                PageNotFoundVisible={PageNotFoundVisible}
                            />
                        ) : (
                            <HeaderIconNav
                                key={link.id}
                                link={link}
                                context={context}
                                toggleDropdown={toggleDropdown}
                                activeDropdownId={activeDropdownId}
                                handleRedirection={handleRedirection}
                            />
                        )
                    )}
                </StyledNavUl>
            </StyledNavContainer>
            {active && <RequestDemo closeHandler={setActive} />}
            {isShowDeleteProjectConfirmationModal && (
                <Alert
                    instanceId="delete_confirmation_modal"
                    onCloseHandler={toggleDeleteConfirmationModal}
                    title={`${COPY.ALERT_DELETE_CONFIRMATION_TITLE}${projectTitle} ?`}
                    isMidSizePopup={true}
                    footer={[
                        {
                            styles: {
                                Button: StyledFooterDeleteButton
                            },
                            label: COPY.ALERT_DELETE_LABEL,
                            variant: BUTTON_TYPE.PRIMARY,
                            onClickHandler: () => {
                                toggleDeleteConfirmationModal();
                                handleDeleteProject(projectId);
                            }
                        },
                        {
                            styles: {
                                Button: StyledFooterCancelButton
                            },
                            label: COPY.ALERT_CANCEL_LABEL,
                            variant: BUTTON_TYPE.INVERT,
                            onClickHandler: () => toggleDeleteConfirmationModal()
                        }
                    ]}
                />
            )}
        </StyledPrimaryNavigation>
    );
};

export default Header;
