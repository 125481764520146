import React from "react";
import styled from "styled-components";
import { blueTick } from "../../app/icons";

const PopupContainer = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40%;
    background-color: white;
    padding: 50px 100px 39px 100px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
`;

const PopupOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
`;

const CloseButton = styled.button`
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
`;

const Header1 = styled.p`
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    margin: 20px;
`;

const SubHeader = styled.p`
    text-align: center;
    color: gray;
    font-size: 14px;
    margin: 30px;

    a {
        color: #007bff;
        text-decoration: none;
    }
`;

const CardHeader = styled.p`
    text-align: center;
    font-size: 18px;
    margin: 20px;
    font-weight: bold;
`;

const CardContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
`;

const Card = styled.div`
    flex: 1;
    margin: 0 10px;
    padding: 20px;
    text-align: center;
    border: 1px solid #ddd;
    border-radius: 8px;

    &:hover {
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    }

    p {
        font-size: 14px;
        color: #333;
    }
`;

const DomainLimitPopup = ({ closePopup }: { closePopup: () => void; }) => {

    return (
        <>
            <PopupOverlay onClick={closePopup} />
            <PopupContainer>
                <CloseButton onClick={closePopup}>×</CloseButton>
                <Header1>Your current ALPS subscription does not include the Project Setup feature.</Header1>
                <SubHeader>
                    Enabling Project Setup gives you powerful tools to plan, audit, and measure your SEO strategy, helping you achieve better results.
                </SubHeader>
                <CardHeader>With this upgrade, you gain access to the following reports:</CardHeader>
                <CardContainer>
                    <Card>
                        <img src={blueTick} alt="Blue Tick" />
                        <p>Share of Voice, Topical Authority</p>
                    </Card>
                    <Card>
                        <img src={blueTick} alt="Blue Tick" />
                        <p>ALPS Scores Analysis, SERP Features Overview</p>
                    </Card>
                    <Card>
                        <img src={blueTick} alt="Blue Tick" />
                        <p>Project Dashboard, Rank Tracking, GSC Insights</p>
                    </Card>
                </CardContainer>
                <SubHeader>
                    To learn more, please contact your Customer Success Manager or reach us at{" "}
                    <a href="mailto:alps@iquanti.com">alps@iquanti.com</a>.
                </SubHeader>
            </PopupContainer>
        </>
    );
};

export default DomainLimitPopup;
