import { handleActions } from "redux-actions";
import { AnyAction } from "redux";

import actionTypes from "./actions";
import { PackDataReducerInterface } from "./types";

const INIT_STATE: PackDataReducerInterface = {
    packTypes: [],
    weekStartDate: "",
    updatedOn: "",
    domainDetails: {},
    baseWeekStartDate: "",
    baseDomainDetails: {}
};

const Reducer = handleActions(
    {
        [actionTypes.SAVE_PACK_DATA]: (state, action: AnyAction) => ({
            ...state,
            packTypes: action.payload.pack_types,
            weekStartDate: action.payload.week_start_date,
            updatedOn: action.payload.updated_on,
            domainDetails: action.payload.domain_details,
            baseWeekStartDate: action.payload.base_week_start_date,
            baseDomainDetails: action.payload.base_domain_details,
        }),
    },
    INIT_STATE
);

export default Reducer;
